// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// Google Analytics 4
import ReactGA from 'react-ga';

export const onRouteUpdate = () => {
  ReactGA.initialize('G-31X0VDMZ7X');
  ReactGA.pageview(window.location.pathname + window.location.search);
};
